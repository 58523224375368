let ChatClient;
import "./package.scss";

try {
	ChatClient = class {
		constructor(props) {
			this.props = {
				origin: "https://widget.zyka.ai",
				user: null,
				...props
			};
			this.iframeHtml = `<iframe class='hidden' src="${this.props.origin}/chatbot/${this.props.licenseKey}?${this.serializeJSONToQueryString(
				{user: this.props.user || {}}
			)}" frameBorder="0" allowFullScreen height='100%' width='100%' onload="setTimeout(() => { this.classList.remove('hidden'); }, 300)"  allow="microphone"></iframe>`;
			this.containerId = "__xlork_chatbot_iframe_container__";
			this.bubbleContainerId = "__xlork_chatbot_iframe_container_bubble__";
			this.bubbleAppended = false; // Flag to track if the bubble message has been appended
			this.recivedMessageFromIframe = this.recivedMessageFromIframe.bind(this);
		}

		appendIframe(src, id) {
			const el = document.getElementById(id);
			if (el) el.remove();
			const container = document.body || document.getElementsByTagName("body")[0];
			const div = document.createElement("div");
			div.id = id;
			div.className = id;
			div.innerHTML = src;
			container.appendChild(div);
		}

		appendBubbleMessage(src) {
			if (this.bubbleAppended) return; // Check if the bubble message has already been appended

			const el = document.getElementById(this.bubbleContainerId);
			if (el) el.remove();
			const container = document.getElementById(this.containerId);
			const div = document.createElement("div");
			div.id = this.bubbleContainerId;
			div.className = this.bubbleContainerId;

			const bubbleContent = document.createElement("div");
			bubbleContent.className = "__xlork_chatbot__bubble_item_";
			bubbleContent.innerHTML = src;

			const closeButton = document.createElement("div");
			closeButton.className = "__xlork_chatbot__close_";
			closeButton.innerHTML = "×";
			closeButton.addEventListener("click", () => this.removeBubble('user'));

			bubbleContent.appendChild(closeButton);
			div.appendChild(bubbleContent);
			container.prepend(div);

			this.bubbleAppended = true; // Set the flag to true after appending the message
			setTimeout(() => {
				this.showBubble();
			}, 5000);
		}

		removeBubble(type) {
			type && sessionStorage.setItem("__xlork_bubble_closed_by_user__", true);
			const bubble = document.getElementById(this.bubbleContainerId);
			if (bubble) {
				bubble.classList.remove("_show_");
				setTimeout(() => {
					bubble?.remove();
				}, 10);
			}
		}

		showBubble() {
			if (sessionStorage.getItem("__xlork_bubble_closed_by_user__") == 'true') return;
			const bubble = document.getElementById(this.bubbleContainerId);
			if (bubble) {
				bubble.classList.add("_show_");
			}
		}

		recivedMessageFromIframe(event) {
			const data = event?.data;
			if (typeof data == "object" && data?.channel == "__xlork_iframe_communication_channel__") {
				let chatbot = data?.value;
				document?.querySelector(`.${this.containerId}`).classList.add(chatbot?.opener_origin || "right");

				switch (data?.type) {
					case "[onOpen]":
						this.removeBubble(); // Remove bubble on open
						document?.querySelector(`.${this.containerId}`).classList.add("open");
						break;
					case "[onClose]":
						this.appendBubbleMessage(chatbot?.message ? `👋 ${chatbot?.message}` : "👋 Hi! I am AI Assistant, How can I help you?", this.bubbleContainerId);
						document?.querySelector(`.${this.containerId}`).classList.remove("open");
						break;
				}
			}
		}

		serializeJSONToQueryString(params) {
    const queryString = Object.keys(params)
        .map(key => {
            const value = params[key];
            if (Array.isArray(value)) {
                return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
            } else if (typeof value === 'object' && value !== null) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        })
        .join('&');
    
    return queryString;
}

		init() {
			this.appendIframe(this.iframeHtml, this.containerId);
			window.addEventListener("message", this.recivedMessageFromIframe, false);
		}
	};
} catch (error) {
	console.error("Error importing package.scss:", error);
	ChatClient = null;
}

export default ChatClient;
